<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500"
    class="col-6"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">Seleccione el día de la Caja</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-col
            cols="12"
          >
            <v-dialog
              ref="dialogdate"
              v-model="modal"
              :return-value.sync="date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Seleccione el día"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                locale="es"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="modal = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialogdate.save(date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              depressed
              @click="closeDialog()"
            >
              Cancelar
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              color="primary"
              depressed
              @click="reporteDate()"
            >
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

// import DataTableCash from './datatables/DataTableCash.vue'

export default {
  // components: {

  //   DataTableCash,

  // },
  data: () => ({
    dialog: false,
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu: false,
    modal: false,
  }),
  mounted() {
    this.dialog = true
  },
  methods: {
    reporteDate() {
      this.dialog = false
      this.$router.push({ name: 'reportcash', params: { fromDate: this.date, toDate: this.date } })
    },
    closeDialog() {
      this.dialog = false
      this.$router.push({ name: 'dashboard' })
    },
  },
}
</script>
